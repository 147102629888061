@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: #f5f5f5;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-list:hover {
  cursor: move;
}

.drop-list:last-child {
  border: none;
}

.drop-list.cdk-drop-list-dragging .drop-list:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.btn {
  color: #212529 !important;
  border: 0 !important;
  background-color: #f1f1f1 !important;
}
.btn:focus {
  outline: none !important;
}

.btn-outline-secondary:hover {
  background-color: #f1f1f1 !important;
  color: #212529 !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #212529;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.bg-primary {
  background-color: #8b2323;
}

.bg-gray {
  background-color: #f1f1f1;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: white;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: white;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
}

blockquote {
  font-style: italic;
  color: #555555;
  padding: 20px 35px 10px;
  border-left: 8px solid #8b2323;
  line-height: 1.6;
  position: relative;
  background: #EDEDED;
}
blockquote:before {
  content: "“";
  color: #8b2323;
  font-size: 3em;
  position: absolute;
  left: 10px;
  top: -10px;
}
blockquote:after {
  content: "";
}
blockquote p {
  margin: 0;
  display: block;
  color: #333333;
  font-style: normal;
}

p {
  margin-bottom: 0;
}

a {
  line-height: 1;
  color: #8b2323;
}
a:hover {
  color: #8b2323;
}

.mat-drawer-container {
  background-color: white !important;
}

*:focus {
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.header {
  color: black;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  transition: 0.2s;
}

.subTitle {
  padding-top: 10px;
  font-size: 0.8rem;
  font-weight: normal;
}

.buttonInHeader {
  flex: 1 1 auto;
  margin: auto;
}

.bottomShadow {
  z-index: 10;
  box-shadow: 0 5px 5px -5px #999;
}

.content {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.stuff {
  flex: auto;
  overflow-y: auto;
}

#loader {
  bottom: 0;
  height: 175px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 175px;
  z-index: 100;
}

#loader .dot {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 87.5px;
}

#loader .dot::before {
  border-radius: 100%;
  content: "";
  height: 87.5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0);
  width: 87.5px;
}

#loader .dot:nth-child(7n+1) {
  transform: rotate(45deg);
}

#loader .dot:nth-child(7n+1)::before {
  animation: 0.8s linear 0.1s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+2) {
  transform: rotate(90deg);
}

#loader .dot:nth-child(7n+2)::before {
  animation: 0.8s linear 0.2s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+3) {
  transform: rotate(135deg);
}

#loader .dot:nth-child(7n+3)::before {
  animation: 0.8s linear 0.3s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+4) {
  transform: rotate(180deg);
}

#loader .dot:nth-child(7n+4)::before {
  animation: 0.8s linear 0.4s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+5) {
  transform: rotate(225deg);
}

#loader .dot:nth-child(7n+5)::before {
  animation: 0.8s linear 0.5s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+6) {
  transform: rotate(270deg);
}

#loader .dot:nth-child(7n+6)::before {
  animation: 0.8s linear 0.6s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+7) {
  transform: rotate(315deg);
}

#loader .dot:nth-child(7n+7)::before {
  animation: 0.8s linear 0.7s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .dot:nth-child(7n+8) {
  transform: rotate(360deg);
}

#loader .dot:nth-child(7n+8)::before {
  animation: 0.8s linear 0.8s normal none infinite running load;
  background: #8b2323 none repeat scroll 0 0;
}

#loader .loading {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  bottom: -40px;
  height: 20px;
  left: 0;
  position: absolute;
  right: 0;
  width: 180px;
}

@keyframes load {
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.spinner-message {
  text-align: center;
}

.ql-toolbar .ql-workLink {
  color: #212529;
  cursor: pointer;
}

.ql-workLink {
  color: #8b2323;
  cursor: pointer;
}

th.mat-mdc-header-cell, td.mat-mdc-cell {
  padding: 8px 8px 8px 0 !important;
}